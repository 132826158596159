<template>
    <div class="row">
        <div class="h5 col-md-6 heading-orange mt-3" style="font-size:14pt">
            1.　基本方針｜目的・基本方針
        </div>
        <div class="p2 mt-3 col-md-4 d-flex align-items-start justify-content-end">
            <input type="button" class="btn btn-bcp-basic btn-sm" value="基本｜1.基本方針 へ進む" v-show="is_completed_introduction" :disabled="isProcessing" @click="showBasic">
        </div>
        <div class="p2 mt-3 col-md-2 d-flex align-items-center justify-content-center ">
            <input type="button" class="btn btn-black btn-sm" value="前ページへ戻る" :disabled="isProcessing" @click="showBack">
        </div>
    </div>

    <levelIcon
            :set_level="setLevel"
        />

    <form @submit.prevent="showNext">
        <div class="row ms-3 mt-5">
            <div class="d-flex align-items-center py-2 font-orange h5">
                <div class="me-3">目的</div>
                <div class="border-bottom flex-grow-1 orange-line"></div>
            </div>
            <div class="row mt-1 ms-3">
                <div class="col-md-10">
                    あなたの{{ callReplaceWord("組織") }}で事業継続計画を作成する目的として、合致するものをすべて選択してください。<br>
                    なお、選択肢は例示ですので、明確な目的がある場合は、「その他」の欄にご入力ください。
                </div>
                <div class="col-md-2">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                        <template #popper>
                            <div class="row">
                                <label>BCPを策定し運用する目的は、緊急時においても事業を継続できるように準備しておくことで、<br>{{ callReplaceWord("従業員") }}とその家族の生命や健康を守った上で、事業を継続して、<br>{{ callReplaceWord("顧客") }}からの信用、{{ callReplaceWord("従業員") }}の雇用、地域経済の活力を守ろうとするものです。                                </label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
            <div class="row mt-4 ms-3">
                <div v-for="(purpose, index) in purpose_list" :key="index">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" :id="'check_' + index" :value="purpose.title" v-model="checkPurpose">
                        <label class="form-check-label" :for="'check_' + index">{{ purpose.title }}</label>
                    </div>
                    <div class="ms-5 mb-4">
                        <label class="form-check-label" :for="'check_' + index">{{ purpose.body }}</label>
                    </div>
                </div>
                <div>
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="check_other" value="その他" v-model="checkPurpose">
                        <label class="form-check-label" for="check_other">その他</label>
                    </div>
                    <div class="ms-5 mb-4">
                        <textarea class="form-control" v-model="textOther" rows="2" :disabled="checkPurpose.indexOf('その他') === -1" @change="addPurpose">

                        </textarea>
                    </div>
                </div>
            </div>
            <div class="row ms-3 mt-4">
                策定の目的
            </div>
            <div class="row ms-3">
                <textarea type="text" class="form-control" :rows="checkPurpose.length < 2 ? 2 : checkPurpose.length + 1" placeholder="" v-model="textForms[0].value" readonly></textarea>
            </div>
        </div>

        <div class="row ms-3 mt-5">
            <div class="d-flex align-items-center py-2 font-orange h5">
                <div class="me-3">基本方針</div>
                <div class="border-bottom flex-grow-1 orange-line"></div>
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 col-md  d-flex align-items-center justify-content-start py-2">
                該当する方針を選択してください。
            </div>
        </div>
        <div v-for="(form, index) in valueForms" :key="index">
            <div class="row ms-3 mt-3">
                <div class="p2 mt-3 col-md-4 d-flex align-items-center justify-content-start">
                    <input type="button" class="btn border btn-light text-dark btn-sm" :class="{ buttoncolor: form.isSelected }" v-model="form.value" :select="form.isSelected" @click="selectedPolicy(index)">
                </div>
                <div class="p2 ms-4 col-md-1 d-flex align-items-center justify-content-start" v-if="index == 0">
                    <VDropdown
                        theme="hint-theme"
                    >
                        <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                        <template #popper>
                            <div class="row">
                                <label>基本方針とは、あなたの{{ callReplaceWord('組織') }}の{{ callReplaceWord('経営') }}方針の延長に位置するもので、BCPを策定するための目的となります。{{ callReplaceWord('従業員') }}の人命を守るため、供給責任を果たし{{ callReplaceWord('顧客') }}からの信用を守るため等、{{ callReplaceWord('経営者') }}の頭の中にはあることを書き出してみます。</label>
                            </div>
                        </template>
                    </VDropdown>
                </div>
            </div>
        </div>

        <div class="row ms-3 mt-3">
            <div class="p2 mt-3 col-md-4 d-flex align-items-center justify-content-start">
                <input type="button" class="btn border btn-light text-dark btn-sm" :class="{ buttoncolor: textForms[1].isSelected }"
                    value="その他&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;"
                    :select="textForms[1].isSelected" @click="selectedOther"
                >
            </div>
            <div class="p2 mt-3 col-md-4 d-flex align-items-center justify-content-start">
                <input type="text" class="form-control d-flex align-items-center justify-content-center py-2" placeholder="" v-model="textForms[1].value">
            </div>
            <div class="p2 ms-4 col-md-1 d-flex align-items-center justify-content-start">
                <VDropdown
                    theme="hint-theme"
                >
                    <input type="button" class="btn btn-sm btn-hint rounded-0 text-nowrap" value="ヒント">
                    <template #popper>
                        <div class="row">
                            <label>上記以外の基本方針が考えられる場合は、記入してください。</label>
                        </div>
                    </template>
                </VDropdown>
            </div>
        </div>

        <div class="row ms-3 mt-5">
            <div class="p2 mt-5 col-md-4 d-flex align-items-start justify-content-start">
                <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="入門｜2.BCPの運用体制 へ進む" :disabled="isProcessing" @click="showNext">
            </div>
            <div class="p2 mt-5 ms-3 col-md-2 d-flex align-items-start justify-content-start">
                <input type="button" class="btn btn-bcp-introduction btn-sm" style="font-size:12pt" value="目次構成 へ戻る" :disabled="isProcessing" @click="showContents">
            </div>
        </div>
    </form>
</template>

<script>
import { ref, reactive, onMounted, watch } from 'vue'
import levelIcon from '../../common/UserLevelIconsComponent.vue'
import ReplaceWord from '../../../function/CommonReplaceWord'
const { getReplaceWord } = ReplaceWord();
import axios from 'axios';

export default {
    components: {
        levelIcon,
    },
    setup(props) {
        onMounted(() => {
            // 取得データをセット
            if(props.bcp_introduction_texts_info.length > 0){
                textForms.forEach(initData =>{
                    props.bcp_introduction_texts_info.forEach(fetchData => {
                        if(initData.columnName == fetchData.column_name){
                            if(initData.columnName === "N00004"){
                                checkPurpose.value = [];
                                let wkText = fetchData.text_value.toString();
                                purpose_list.forEach(item => {
                                    if(fetchData.text_value.toString().indexOf(item.title) > -1){
                                        checkPurpose.value.push(item.title);
                                        wkText = wkText.replace(item.title, "");
                                    }
                                });

                                wkText = wkText.replace(/(\r\n|\n|\r)/g, "");

                                if(wkText.length > 0){
                                    checkPurpose.value.push("その他");
                                    textOther.value = wkText;
                                }
                            }else{
                                initData.value = fetchData.text_value;
                                initData.isSelected = true;
                            }
                        }
                    });
                })
             }

             if(props.bcp_introduction_values_info.length > 0){
                valueForms.forEach(initData =>{
                    props.bcp_introduction_values_info.forEach(fetchData => {
                        if(initData.columnName == fetchData.column_name){
                            initData.isSelected = true;
                        }
                    });
                })
             }
        });

        // 戻るボタン制御
        if (sessionStorage.getItem('refresh') == 'true') {
            sessionStorage.removeItem('refresh');
            window.location.reload();
        }

        const setLevel = ref('100000');
        const isProcessing = ref(false);
        const isSelected = ref(false);

        const callReplaceWord = (word) => {
            return getReplaceWord(word, props.replacement_word_list)
        }

        const textForms = reactive([
            {
                columnName: 'N00004',
                value: '',
            },
            {
                columnName: 'N00010',
                value: '',
                isSelected: false,
            },
        ]);

        const valueForms = reactive([
            {
                columnName: 'N00005',
                value: '人命（' + callReplaceWord('従業員') + '・' + callReplaceWord('顧客') + '）の安全を守る　　　　　　  ',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00006',
                value: callReplaceWord('当社') + 'の' + callReplaceWord('経営') + 'を維持する　　　　　　　　　　　　  ',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00007',
                value: '供給責任を果たし、' + callReplaceWord('顧客') + 'からの信用を守る　　　  ',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00008',
                value: callReplaceWord('従業員') + 'の' + callReplaceWord('雇用') + 'を守る　　　　　　　　　　　　　  ',
                seq: 1,
                isSelected: false,
            },
            {
                columnName: 'N00009',
                value: '地域経済の活力を守る　　　　　　　　　　　　  ',
                seq: 1,
                isSelected: false,
            },
        ]);

        const purpose_list = reactive([
            {
                title: "社会的責任を履行するため",
                body: "BCPを策定・運用することで、" + callReplaceWord("当社") + "が存在意義を再確認し、社会的責任を積極的に果たす姿勢を示します。また" + callReplaceWord("顧客") + "や" + callReplaceWord("取引先") + "との信頼関係を強化する要素にします。" + callReplaceWord("組織") + "が、自然災害や予期せぬ事故、社会的混乱などの緊急事態に直面した際に、迅速に" + callReplaceWord("事業") + "を再開し、" + callReplaceWord("顧客") + "や" + callReplaceWord("取引先") + "への影響を最小限に抑えることで、社会的な信用を維持し、" + callReplaceWord("組織") + "としての責任を果たすことを目的とします。",
            },
            {
                title: "経済的損失を最小化にするため",
                body: "BCPを策定・運用することで、災害やシステム障害、サプライチェーンの断絶などによる経済的損失を最小限に抑えるために" + callReplaceWord("組織") + "の経済的な持続可能性を確保します。またリスクマネジメントの一環として" + callReplaceWord("経営") + "の安定性を高めるものになります。これにより、" + callReplaceWord("事業") + "の中断による" + callReplaceWord("売上") + "減少や、復旧に要する費用の増大を防ぐことを目的とします。",
            },
            {
                title: "人的資源の保護のため",
                body: "BCPを策定・運用することで、" + callReplaceWord("組織") + "において最も重要な資産である人材を保護し、彼らの信頼を得るために取り組みます。また、" + callReplaceWord("従業員") + "の安心感を高めることにより、" + callReplaceWord("組織") + "文化の強化や業務効率の向上にも寄与します。また、" + callReplaceWord("従業員") + "や" + callReplaceWord("取引先") + "など、" + callReplaceWord("組織") + "に関わるすべての人々の安全と健康を守るために、災害時には迅速かつ適切な対応をとります。" + callReplaceWord("従業員") + "の命を守り、" + callReplaceWord("組織") + "全体の士気を維持することを目的とします。",
            },
            {
                title: "レピュテーションリスクを管理するため",
                body: "BCPを策定・運用することで、" + callReplaceWord("組織") + "の危機管理能力を社会に示し、レピュテーションリスク（企業のネガティブな情報拡散や、評判の悪化による信頼の低下）を軽減させます。結果として、" + callReplaceWord("顧客") + "・" + callReplaceWord("株主") + "・" + callReplaceWord("従業員") + "からの信頼を維持・向上させます。緊急事態における適切な対応を通じて、" + callReplaceWord("組織") + "の信頼性とブランド価値を守り、レピュテーションリスクを軽減することを目的とします。",
            },
            {
                title: "法的・規制要件を遵守するため",
                body: "BCPを策定・運用することで、法令遵守の姿勢を示します。BCPは業界標準に適合するための要件を満たす重要な要素であるため、行政機関からの信頼を得ることに繋がります。業界や地域の規制、法的要件を遵守し、特に金融業界や重要インフラに関わる" + callReplaceWord("当社") + "において、BCP策定が義務付けられている要件を満たすことを目的とします。",
            },
        ]);

        const selectedPolicy = (index) => {
            valueForms[index].isSelected = !valueForms[index].isSelected ? true : false;
        }

        const selectedOther = () => {
            textForms[1].isSelected = !textForms[1].isSelected ? true : false;
        }

        const showBasic = async () => {
            // 目的と基本方針を登録
            const result = await registerIntroduction2();

            if (result == '200') {
                // 基本コースページ遷移
                const url = '/bcp/bcp_first/1';
                location.href = url;
            }
        }

        const showBack = async () => {
            // 目的と基本方針を登録
            const result = await registerIntroduction2();

            if (result == '200') {
                // 前のページへ遷移
                sessionStorage.setItem('refresh', 'true');
                history.back();
            }
        }

        const showNext = async () => {
            // 目的と基本方針を登録
            const result = await registerIntroduction2();

            if (result == '200') {
                // 次のページへ遷移
                const url = '/bcp/bcp_introduction/3';
                location.href = url;
            }
        }

        const showContents = async () => {
            // 目的と基本方針を登録
            const result = await registerIntroduction2();

            if (result == '200') {
                // 目次ページへ遷移
                const url = '/bcp/bcp_introduction/目';
                location.href = url;
            }
        }

        // 目的・基本方針登録
        const registerIntroduction2 = async () => {
            isProcessing.value = true;
            const postUrl = '/api/bcp/registerIntroduction2';

            return await axios.post(postUrl, {
                'bcp_id': props.bcp_basic_frame_info.id,
                'version': props.bcp_basic_frame_info.version,
                'text_form_list': textForms,
                'value_form_list': valueForms.filter(data => data.isSelected === true),
            }).then(res => {
                // visibleFlashMessage('正常に登録されました', 'success');
                console.log(res);
                return res.status;
            }).catch(err => {
                console.log(err);
                return err.response?.status;
            }).finally(fin => {
                isProcessing.value = false;
            });
        }

        const checkPurpose = ref([]);
        const textOther = ref("");

        let oldTextOther = "";
        const addPurpose = () => {
            let wkArray = []
            wkArray = textForms[0].value.split("\r\n");
            if(oldTextOther.length > 0){
                wkArray = wkArray.filter(item => item !== oldTextOther);
            }
            wkArray.push(textOther.value);
            textForms[0].value = wkArray.join('\r\n');
            oldTextOther = textOther.value;
        }

        watch(checkPurpose, (newVal, oldVal) => {
            if(checkPurpose.value.indexOf("その他") > -1){
                const filteredArray = checkPurpose.value.filter(item => item !== "その他");

                filteredArray.push(textOther.value);

                textForms[0].value = filteredArray.join('\r\n');
            }else{
                textForms[0].value = checkPurpose.value.join("\r\n");
            }
        });

        return {
            setLevel,
            textForms,
            valueForms,
            isProcessing,
            isSelected,
            callReplaceWord,
            selectedPolicy,
            selectedOther,
            showBasic,
            showBack,
            showNext,
            showContents,

            purpose_list,
            checkPurpose,
            textOther,
            addPurpose,
        }
    },
    props:{
        corp_info: {
            type: Object,
        },
        replacement_word_list: {
            type: Array,
        },
        is_completed_introduction: {
            type: Boolean,
        },
        bcp_basic_frame_info: {
            type: Object,
        },
        bcp_introduction_texts_info: {
            type: Object,
        },
        bcp_introduction_values_info: {
            type: Object,
        },
    },
}
</script>

<style lang="scss" scoped>
@use '../../../../sass/common_colors.scss';
.font-orange {
    color: common_colors.colors(resilience-orange);
}

.buttoncolor {
    background-color: #c6c7c8;
}

.orange-line {
  height: 1px; /* 線の太さを調整 */
  color: common_colors.colors(resilience-orange);
  border-color: common_colors.colors(resilience-orange) !important;
}
</style>
